//@ts-check
import React from "react";
import Layout from "../../components/Layout";
import Contact from "../../components/Contact";

const ContactPage = () => {
  return (
    <Layout
      helmet={{
        title: "Contact",
        description: "Contact us",
        url: "contact",
      }}
    >
      <Contact />
    </Layout>
  );
};

export default ContactPage;
