import { webpxpng } from "../../utils";

const prefix = process.env.PUBLIC_URL + "/clients/";

const bergenbier = webpxpng("bergenbier");
const cocacola = "cocacola.png";
const cristim = webpxpng("cris tim-01");
const efden = webpxpng("efden-01");
const gmp = "gmp pr-01.png";
const gsk = webpxpng("gsk-01");
const henkel = "henkel-01.png";
const holcim = "holcim-01.png";
const iqos = webpxpng("iqos ok-01");
const kaufland = "kaufland-01.png";
const lidl = "lidl-01.png";
const liebher = webpxpng("liebher-01");
const mussete = webpxpng("mussete-01");
const nickeleidion = "nickeleidion-01.png";
const onminasig = webpxpng("onminasig-01");
const pepsi = "pepsi-01.png";
const perwoll = webpxpng("perwoll-01");
const redbull = webpxpng("redbull-01");
const seeding = "seeding knowledge-01.png";
const syoss = webpxpng("syoss-01");
const unilever = webpxpng("unilever-01");
const ursus = webpxpng("ursus-01");
const google = "google.png";
const ingnn = "ingnn.png";
const iebn = webpxpng("iebn");
const staropramen = webpxpng("staropramen");
const discovery = webpxpng("discovery-01");

export default {
  Discovery: prefix + discovery,
  Pepsi: prefix + pepsi,
  Kaufland: prefix + kaufland,
  Google: prefix + google,
  NN: prefix + ingnn,
  Efden: prefix + efden,
  GMP: prefix + gmp,
  GSK: prefix + gsk,
  Henkel: prefix + henkel,
  Redbull: prefix + redbull,
  Bergenbier: prefix + bergenbier,
  Lidl: prefix + lidl,
  Holcim: prefix + holcim,
  Cristim: prefix + cristim,
  Iqos: prefix + iqos,
  Liebher: prefix + liebher,
  "Coca-Cola": prefix + cocacola,
  Mussete: prefix + mussete,
  Onminasig: prefix + onminasig,
  Nickeleidion: prefix + nickeleidion,
  Perwoll: prefix + perwoll,
  Ursus: prefix + ursus,
  Seeding: prefix + seeding,
  Syoss: prefix + syoss,
  Unilever: prefix + unilever,
  IEBN: prefix + iebn,
  Staropramen: prefix + staropramen,
};
