//@ts-check
import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // @ts-ignore
      paddingBottom: theme.spacing(8),
    },
    leftDecorator: {
      width: 120,
      position: "relative",
      // @ts-ignore
      [theme.breakpoints.down("sm")]: {
        width: 80,
      },
      // @ts-ignore
      [theme.breakpoints.down("xs")]: {
        // @ts-ignore
        width: theme.spacing(1) + 4,
      },
    },
    decoratorLine: {
      position: "absolute",
      // @ts-ignore
      top: theme.spacing(3) + 18,
      // @ts-ignore
      right: theme.spacing(4),
      // @ts-ignore
      [theme.breakpoints.down("xs")]: {
        // @ts-ignore
        right: theme.spacing(1),
        height: 48,
        // @ts-ignore
        top: theme.spacing(3),
      },
      left: 0,
      height: 4,
      // @ts-ignore
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      // @ts-ignore
      paddingTop: theme.spacing(3),
      // @ts-ignore
      paddingBottom: theme.spacing(6),
    },
    divider: {
      // @ts-ignore
      marginBottom: theme.spacing(8),
    },
    content: {
      maxWidth: "calc(100% - 120px)",

      // @ts-ignore
      [theme.breakpoints.down("xs")]: {
        maxWidth: "calc(100% - 12px)",
      },
    },
  })
);

const Headline = ({ title, children, divider }) => {
  const classes = useStyles({});
  return (
    <>
      <Grid container direction="row" className={classes.root}>
        <Grid item className={classes.leftDecorator}>
          <div className={classes.decoratorLine} />
        </Grid>
        <Grid item xs className={classes.content}>
          <div style={{ position: "relative" }}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h4" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
              <Grid item style={{ maxWidth: "100%" }}>
                {children}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {divider && <Divider className={classes.divider} />}
    </>
  );
};

Headline.defaultProps = {
  divider: false,
};

export default Headline;
