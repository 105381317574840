//@ts-check
import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import Layout from "../../components/Layout";
import Contact from "../../components/Contact";
import Grayscale from "../../components/Grayscale";
import { webpxjpg } from "../../utils";

const prefix = process.env.PUBLIC_URL + "/images/";
const WhyUsImg = prefix + webpxjpg("WhyUs");

/**@type {any} */
const subheader = {
  marginTop: 16,
  marginBottom: 8,
  fontWeight: "bold",
  fontSize: "1.2rem",
};

const WhyChooseUs = () => {
  return (
    <Layout
      helmet={{
        title: "Why us",
        description: "Why choose us",
        url: "why-choose-us",
      }}
      secondChildren={<Contact />}
    >
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography style={subheader}>Excellence</Typography>
          <Typography>
            We always strive for excellence and perfection but what makes us
            truly special and differentiates us from other players on the
            market, is the sheer dedication and enthusiasm our team shares for
            illustrious video and impactful advertising.
          </Typography>

          <Typography style={subheader}>Flexibility</Typography>
          <Typography>
            Our numerous media assets - as well as a diversified team with
            background experience in most creative fields - opens the way to
            infinite possibilities and out of the box variations that will match
            your vision to the dot.
          </Typography>

          <Typography style={subheader}>Integration</Typography>
          <Typography>
            We bring that unique ultimate mix to the table: multimedia
            production plus full creative services, all wrapped up in a
            personalized package that will fulfil your brand’s needs while
            optimizing your available budget.
          </Typography>

          <Typography style={subheader}>Professionalism</Typography>
          <Typography>
            And to top it off, with us, you have the certainty of working with
            the best in the field. Our team is exclusively composed of
            professionals with over a decade of experience; both with agencies
            and clients pertaining to a broad range of domains.
          </Typography>
          <Typography style={{ marginBottom: 16 }}>
            Give us a call or send us an email and we’ll figure out together the
            best way to breathe life into your project and <b>make it happen</b>
            .
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Hidden xsDown>
            <div style={{ marginLeft: 40 }}>
              <Grayscale src={WhyUsImg} alt="Why choose us" />
            </div>
          </Hidden>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default WhyChooseUs;
