//@ts-check
import React from "react";
import { Grid, TextField, Button, Typography, Link } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import Headline from "../Headline";
import sendEmail from "./sendEmail";
import MaterialForm from "./MaterialForm";
import LazyLoad from "react-lazyload";

const CONTACT_PHONE = "+40721902433";
const CONTACT_EMAIL = "office@writecut.com";

const useStyles = makeStyles((theme) =>
  createStyles({
    name: {
      // @ts-ignore
      [theme.breakpoints.up("md")]: {
        maxWidth: 400,
      },
    },
    email: {
      // @ts-ignore
      [theme.breakpoints.up("md")]: {
        maxWidth: 400,
      },
    },
  })
);

const STATE = {
  INITIAL: 0,
  SENDING: 1,
  SENT: 2,
};

const ContactForm = () => {
  const classes = useStyles({});
  const [state, setState] = React.useState(STATE.INITIAL);
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [message, setMessage] = React.useState("");

  return (
    <div>
      <div id="letstalk" style={{ marginTop: -64, paddingTop: 64 }} />
      <LazyLoad once>
        <MaterialForm
          autoComplete="on"
          disableSubmitButtonOnError={false}
          onSubmit={(values) => {
            if (!email) return alert("Missing email");
            if (!name) return alert("Missing name");
            if (!message) return alert("Missing message");
            if (email.indexOf("@") === -1) return alert("Invalid email");

            setState(STATE.SENDING);
            sendEmail({
              from: email,
              name,
              message,
            }).then(() => {
              setState(STATE.SENT);
            });
          }}
        >
          <Headline title="Let's talk">
            <Grid container direction="column">
              <Grid item>
                <Grid container direction="column" spacing={4}>
                  <Grid item>
                    <TextField
                      className={classes.name}
                      disabled={state !== STATE.INITIAL}
                      fullWidth
                      label="Name"
                      type="text"
                      id="name"
                      name="name"
                      data-validators="isRequired"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.email}
                      disabled={state !== STATE.INITIAL}
                      fullWidth
                      label="Email"
                      type="text"
                      id="email"
                      name="email"
                      data-validators="isRequired,isEmail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={state !== STATE.INITIAL}
                      label="Message"
                      type="text"
                      multiline
                      fullWidth
                      rows={3}
                      id="message"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    {state === STATE.SENT ? (
                      <Typography>Email was sent. Thanks!</Typography>
                    ) : (
                      <Button
                        name="Send email"
                        disabled={state === STATE.SENDING}
                        variant="contained"
                        type="submit"
                      >
                        {state === STATE.SENDING ? "Sending..." : "Send"}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <div style={{ height: 48 }} />
                <Line label="Phone" value={CONTACT_PHONE} protocol="tel" />
                <Line label="Email " value={CONTACT_EMAIL} protocol="mailto" />
              </Grid>
            </Grid>
          </Headline>
        </MaterialForm>
      </LazyLoad>
    </div>
  );
};

const Line = ({ label, value, protocol }) => {
  return (
    <div>
      <Typography
        display="inline"
        style={{ marginRight: 16, fontWeight: "bold" }}
      >
        {label}
      </Typography>
      <Typography display="inline">
        <Link color="inherit" href={protocol + ":" + value}>
          {value}
        </Link>
      </Typography>
    </div>
  );
};

export default ContactForm;
