import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";

const NavLinkComp = React.forwardRef((props, ref) => (
  <NavLink exact innerRef={ref} {...props} />
));

const Link = (props) => {
  return <Button name="Link" component={NavLinkComp} {...props} />;
};

export default Link;
