//@ts-check
import React from "react";
import Theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import Pages from "./pages";

function App() {
  return (
    <Theme>
      <BrowserRouter>
        <Pages />
      </BrowserRouter>
    </Theme>
  );
}

export default App;
