import React, { Suspense } from "react";

const ReactPlayer = React.lazy(() => import("react-player"));

const ReactPlayerLib = (props) => {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <ReactPlayer {...props} />
      </Suspense>
    </div>
  );
};

export default ReactPlayerLib;
