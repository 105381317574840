//@ts-check
import { createMuiTheme } from "@material-ui/core/styles";

const serif = {
  fontFamily: ['"Playfair Display"', "serif"].join(","),
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Roboto"', "sans-serif"].join(","),
    h6: {
      ...serif,
      fontWeight: 600,
    },
    h4: {
      ...serif,
      fontSize: "2rem",
      fontWeight: 600,
    },
    h5: {
      ...serif,
    },
    h3: {
      ...serif,
      fontSize: "2.8rem",
      textTransform: "uppercase",
      fontWeight: 600,
    },
    button: {
      ...serif,
      fontWeight: 600,
    },
    overline: {
      fontWeight: 600,
      color: "rgba(0,0,0,0.6)",
    },
  },
  palette: {
    primary: {
      light: "#6F57C9",
      main: "#6F57C9",
      dark: "#6F57C9",
      contrastText: "#fff",
    },
    secondary: {
      light: "#33d1d5",
      main: "#33d1d5",
      dark: "#33d1d5",
      contrastText: "#fff",
    },
  },
  props: {
    MuiTextField: {
      variant: "filled",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        borderRadius: 16,
      },
    },
    MuiButton: {
      contained: {
        borderRadius: 16,
        paddingLeft: 32,
        paddingRight: 32,
        textTransform: "none",
        color: "white",
        backgroundColor: "#5b42b8",
        backgroundImage: "linear-gradient(to right, #5b42b8 0%,#33d1d5 100%);",
        marginTop: -1,
        "&:hover": {
          backgroundImage:
            "linear-gradient(to right, #5b42b8 0%,#4492c8 100%);",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.64)",
      },
    },
  },
});

export default theme;
