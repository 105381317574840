//@ts-check
import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Portfolio from "./Portfolio";
import Clients from "./Clients";
import Contact from "./Contact";
import WhyChooseUs from "./WhyChooseUs";
import PageNotFound from "./PageNotFound";

const AppRouter = () => {
  return (
    <Switch>
      <Route path="/about" exact component={About} />
      <Route path="/portfolio" exact component={Portfolio} />
      <Route path="/why-choose-us" exact component={WhyChooseUs} />
      <Route path="/clients" exact component={Clients} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/" exact component={Home} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default AppRouter;
