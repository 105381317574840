//@ts-check
import React from "react";
import InfiniteCarousel from "react-leaf-carousel";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import DATA from "./data";

const data = Object.keys(DATA);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .InfiniteCarouselArrowPrev": {
        marginLeft: -70,
        "& i": {
          borderColor: "#acacac",
        },
      },

      "& .InfiniteCarouselArrowNext": {
        marginRight: -42,
        "& i": {
          borderColor: "#acacac",
        },
      },
    },
  })
);

const Carousel = () => {
  const classes = useStyles({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.root}>
      <InfiniteCarousel
        key={matches}
        dots={false}
        showSides
        sideSize={matches ? 0.25 : 0.5}
        sidesOpacity={0.3}
        slidesToScroll={matches ? 1 : 3}
        slidesToShow={matches ? 1 : 3}
        arrows={!matches}
        autoCycle
        cycleInterval={2000}
        animationDuration={1600}
        scrollOnDevice
        prevArrow={
          <button
            name="Previous Client"
            aria-label="Previous Client"
            className="InfiniteCarouselArrow InfiniteCarouselArrowPrev"
            type="button"
          >
            <i className="InfiniteCarouselArrowIcon InfiniteCarouselArrowPrevIcon"></i>
          </button>
        }
        nextArrow={
          <button
            name="Next Client"
            aria-label="Next Client"
            className="InfiniteCarouselArrow InfiniteCarouselArrowNext"
            type="button"
          >
            <i className="InfiniteCarouselArrowIcon InfiniteCarouselArrowNextIcon"></i>
          </button>
        }
      >
        {data.map((d) => (
          <div key={d}>
            <img alt={d} src={DATA[d]} />
          </div>
        ))}
      </InfiniteCarousel>
    </div>
  );
};

export default Carousel;
