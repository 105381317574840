//@ts-check
import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import Layout from "../../components/Layout";
import Contact from "../../components/Contact";
import Thumbnail from "../../components/Thumbnail";
import Headline from "../../components/Headline";
import Carousel from "../Clients/Carousel";
import LazyLoad from "react-lazyload";
import { webpxjpg } from "../../utils";

const prefix = process.env.PUBLIC_URL + "/images/";
const Hero = prefix + webpxjpg("Hero");
const Copyright = prefix + webpxjpg("CopywritingServices");
const VideoProd = prefix + webpxjpg("VideoProduction");
const VideoPreview = prefix + webpxjpg("VideoPreview");

const useStyles = makeStyles((theme) =>
  createStyles({
    middle: {
      fontSize: "3.1rem",
      marginTop: 8,
      marginBottom: 8,
      whiteSpace: "nowrap",

      borderTop: "1px solid white",
      borderBottom: "1px solid white",
      paddingTop: 8,
      paddingBottom: 8,

      // @ts-ignore
      [theme.breakpoints.down("md")]: {
        fontSize: "3rem",
      },
      // @ts-ignore
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
        marginLeft: 16,
        marginRight: 16,
        whiteSpace: "unset",
        letterSpacing: "6px",
      },
    },
    top: {
      fontSize: "2rem",
      // @ts-ignore
      [theme.breakpoints.down("md")]: {
        fontSize: "1.6rem",
      },
    },
    bottom: {
      fontSize: "2rem",
      // @ts-ignore
      [theme.breakpoints.down("md")]: {
        fontSize: "1.6rem",
      },
    },
  })
);

const Home = () => {
  const classes = useStyles({});
  const [reel, setReel] = React.useState(false);

  React.useEffect(() => {
    if (reel) {
      document.getElementById("discover").scrollIntoView({
        behavior: "smooth",
      });
      setTimeout(() => {
        setReel(false);
      }, 500);
    }
  }, [reel]);

  return (
    <Layout
      helmet={{
        title: "Writecut - Remarkable content creation",
        noPrefix: true,
      }}
      hero={{
        title: (
          <>
            <div className={classes.top}>A new visual era</div>
            <div className={classes.middle}>conceptual multimedia</div>
            <div className={classes.bottom}>production</div>
          </>
        ),
        description:
          "The remarkable content you need, from creative concept and copy to art direction and video production.",
        action: "Discover now",
        src: Hero,
        onClick: () => {
          setReel(true);
        },
      }}
      offsetPx={400}
      secondChildren={
        <>
          <div style={{ height: 8 * 5 }} />

          <Headline title="Clients" divider>
            <div style={{ height: 8 }} />
            <LazyLoad height={95} offset={100}>
              <Carousel />
            </LazyLoad>
            <div style={{ height: 8 }} />
          </Headline>

          <Contact />
        </>
      }
    >
      <Typography
        variant="h4"
        align="center"
        style={{ marginTop: 64, marginBottom: 16, letterSpacing: "1px" }}
      >
        Express your brand’s personality through captivating content
      </Typography>

      <div style={{ height: 8 * 10 }} />

      <Grid container direction="row">
        <Grid item xs={12} sm={6}>
          <Thumbnail
            height={300}
            title="Multimedia"
            description="video · animation · interactive tech"
            image={VideoProd}
            to="/portfolio#multimedia"
            button="Explore more"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Thumbnail
            height={300}
            title="Creative"
            description="copy · graphics · strategy"
            image={Copyright}
            to="/portfolio#creative"
            button="Take a look"
          />
        </Grid>
      </Grid>

      <div id="discover" style={{ height: 20 }} />
      <Typography
        component="p"
        variant="h6"
        align="center"
        style={{
          marginTop: 40,
          marginBottom: 8,
          textTransform: "uppercase",
          fontSize: "1.6rem",
        }}
      >
        Watch{" "}
        <span style={{ fontSize: "2.4rem", marginLeft: 4, marginRight: 4 }}>
          10
        </span>{" "}
        years of experience in
        <span style={{ fontSize: "2.4rem", marginLeft: 6 }}>1</span> minute
      </Typography>
      <Thumbnail
        video="https://videos.writecut.com/reel-2020.mp4"
        preview={VideoPreview}
      />
    </Layout>
  );
};

export default Home;
