//@ts-check
import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, IconButton } from "@material-ui/core";
import { MdArrowDownward as ArrowIcon } from "react-icons/md";
import Link from "../Link";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      textAlign: "center",
      // @ts-ignore
      [theme.breakpoints.down("xs")]: {
        marginTop: 48,
      },
    },
    image: {
      backgroundColor: "#051127",
      width: "100%",
      height: "80vh",
      objectFit: "cover",
      // @ts-ignore
      [theme.breakpoints.down("sm")]: {
        height: "70vh",
        minWidth: "100%",
        maxWidth: "100%",
        width: "auto",
      },
      "@media (orientation: landscape) and (max-width: 700px)": {
        height: "100vh",
      },
    },
    wrapper: {
      position: "absolute",
      top: "25%",
      bottom: "10%",
      left: 0,
      right: 0,
      maxWidth: 680,
      margin: "auto",
      // @ts-ignore
      [theme.breakpoints.down("sm")]: {
        top: 120,
        maxWidth: 640,
      },
      "@media (orientation: landscape) and (max-width: 700px)": {
        top: 25,
      },
      // @ts-ignore
      [theme.breakpoints.up("md")]: {
        transform: "scale(1.2)",
        transformOrigin: "center bottom",
      },
    },
    title: {
      color: "white",
      // @ts-ignore
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
      // @ts-ignore
      [theme.breakpoints.down("xs")]: {
        marginTop: -72,
      },
    },
    description: {
      color: "white",
      opacity: 0.7,
      fontSize: "1rem",
      marginTop: 48,
      marginBottom: -40,
      // @ts-ignore
      [theme.breakpoints.up("sm")]: {
        marginBottom: -16,
      },
    },
    button: {
      transform: "scale(1.33)",
      // @ts-ignore
      paddingLeft: theme.spacing(4),
      // @ts-ignore
      paddingRight: theme.spacing(4),

      position: "absolute",
      bottom: 0,
      marginLeft: -78,
    },
    arrowIndicator: {
      position: "absolute",
      bottom: 0,
      left: "50%",
      marginLeft: -16,
      marginBottom: -10,
      width: 32,
      height: 32,
      borderRadius: "50%",
      // @ts-ignore
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        // @ts-ignore
        backgroundColor: theme.palette.primary.main,
      },
      color: "white",
      paddingTop: 8,
      textAlign: "center",
      "& > span": {
        width: 18,
        height: 18,
        marginTop: 4,
      },
    },
    arrowIndicatorIcon: {
      // fontSize: "1.2rem",
    },
  })
);

const Hero = ({ src, title, description, action, onClick }) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <img src={src} className={classes.image} alt="Write cut hero" />
      <div className={classes.wrapper}>
        <Typography className={classes.title} align="center" variant="h3">
          {title}
        </Typography>
        {description && (
          <Typography className={classes.description} align="center">
            {description}
          </Typography>
        )}
        <Link
          className={classes.button}
          variant="contained"
          to="/#discover"
          onClick={onClick}
        >
          {action}
        </Link>
      </div>
      <IconButton
        name="View more"
        aria-label="View more"
        onClick={(e) =>
          // @ts-ignore
          e.target.scrollIntoView({
            block: "start",
            inline: "nearest",
            behavior: "smooth",
          })
        }
        className={classes.arrowIndicator}
      >
        <ArrowIcon className={classes.arrowIndicatorIcon}></ArrowIcon>
      </IconButton>
    </div>
  );
};

export default Hero;
