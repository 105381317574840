//@ts-check
import React from "react";
import { Tooltip } from "@material-ui/core";
import Layout from "../../components/Layout";
import Contact from "../../components/Contact";
import DATA from "./data";

const data = Object.keys(DATA);
const scale = 1.5;

const Clients = () => {
  return (
    <Layout
      helmet={{
        title: "Clients",
        description: "Clients",
        url: "clients",
      }}
      secondChildren={<Contact />}
    >
      {data.map((d) => (
        <div
          key={d}
          style={{
            padding: 4,
            backgroundColor: "white",
            display: "inline-block",
            margin: 8,
            border: "1px solid lightgrey",
          }}
        >
          <Tooltip title={d}>
            <img
              alt={d}
              src={DATA[d]}
              style={{
                width: 240 * scale,
                height: 70 * scale,
              }}
            />
          </Tooltip>
        </div>
      ))}
    </Layout>
  );
};

export default Clients;
