import React from "react";
import Logo from "../Logo";
import {
  Toolbar,
  Container,
  Typography,
  useMediaQuery,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles, createStyles, useTheme } from "@material-ui/styles";
import { MdMail, MdPhone } from "react-icons/md";
import Link from "../Link";
import { RiVimeoFill } from "react-icons/ri";

const MENU = [
  // {
  //   label: {
  //     xl: "Home",
  //     lg: "Home",
  //     md: "Home",
  //     sm: null,
  //     xs: null,
  //   },
  //   path: "/",
  // },
  {
    label: {
      xl: "Who we are",
      lg: "Who we are",
      md: "Who we are",
      sm: "Who we are",
      xs: "Who we are",
    },
    path: "/about",
  },
  {
    label: {
      xl: "What we do",
      lg: "What we do",
      md: "What we do",
      sm: "What we do",
      xs: "What we do",
    },
    path: "/portfolio",
  },
  {
    label: {
      xl: "Why us",
      lg: "Why us",
      md: "Why us",
      sm: "Why us",
      xs: "Why us",
    },
    path: "/why-choose-us",
  },
  {
    label: {
      xl: "Clients",
      lg: "Clients",
      md: "Clients",
      sm: null,
      xs: null,
    },
    path: "/clients",
  },
  {
    label: {
      xl: "Contact",
      lg: "Contact",
      md: "Contact",
      sm: null,
      xs: null,
    },
    path: "/contact",
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#120813",
      color: "white",
    },
    selected: {},
    logo: {
      display: "inline-block",
    },
    link: {
      height: 48,
      [theme.breakpoints.up("sm")]: {
        height: 58,
      },
      [theme.breakpoints.up("md")]: {
        height: 64,
      },
      // "&:first-child": {
      //   marginLeft: 32,
      //   [theme.breakpoints.down("xs")]: {
      //     marginLeft: 0,
      //   },
      // },
      opacity: 0.7,
      "&:hover": {
        opacity: 0.9,
      },
      "&$selected": {
        opacity: 1,
      },
    },
    container: {
      maxWidth: 1380,
      display: "flex",
      textAlign: "center",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    menu: {
      display: "inline-block",
      flex: "auto",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },

      [theme.breakpoints.only("sm")]: {
        textAlign: "right",
      },
    },
    contactActions: {
      width: 242,
      textAlign: "right",
      display: "inline-block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key), {
        defaultMatches: key === "md" ? true : false,
      });
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

const NavBar = () => {
  const width = useWidth();
  const classes = useStyles({});

  const entries = MENU.map((e) => {
    return {
      ...e,
      label: e.label[width],
    };
  }).filter((e) => !!e.label);

  return (
    <Toolbar className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.menu}>
          {entries.map((entry) => (
            <Link
              name={entry.label}
              color="inherit"
              className={classes.link}
              activeClassName={classes.selected}
              to={entry.path}
              key={entry.label}
            >
              <Typography variant="body2">{entry.label}</Typography>
            </Link>
          ))}
        </div>
        <div className={classes.contactActions}>
          <Grid
            container
            alignItems="center"
            alignContent="stretch"
            style={{ height: "100%" }}
          >
            <Grid item xs>
              <IconButton
                color="inherit"
                // size="small"
                name="Contact by phone"
                aria-label="Contact by phone"
                onClick={(e) => {
                  document.body.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                  });
                }}
              >
                <MdPhone />
              </IconButton>
              <IconButton
                // size="small"
                color="inherit"
                name="Contact by email"
                aria-label="Contact by email"
                onClick={(e) => {
                  document.getElementById("letstalk").scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                <MdMail />
              </IconButton>

              <IconButton
                style={{ display: "none" }}
                color="inherit"
                // size="small"
                name="View vimeo account"
                aria-label="View vimeo account"
                onClick={(e) => {
                  window.open("https://vimeo.com/455564371");
                }}
              >
                <RiVimeoFill />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Toolbar>
  );
};

export default NavBar;
