//@ts-check
import React from "react";
import NavBar from "../NavBar";

const Footer = () => {
  return (
    <div style={{ overflowY: "auto", backgroundColor: "#120813" }}>
      <NavBar />
    </div>
  );
};

export default Footer;
