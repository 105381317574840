const URL = "https://us-central1-writecut-d9870.cloudfunctions.net/sendEmail";

const sendEmail = async ({ from, name, message }) => {
  const response = await fetch(URL, {
    method: "POST",
    mode: "no-cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({
      from,
      name,
      message,
    }),
  });
  return response;
};

export default sendEmail;
