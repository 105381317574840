let support;
export const isWebpSupported = () => {
  if (typeof support !== "undefined") return support;

  try {
    const elem =
      typeof document === "object" ? document.createElement("canvas") : {};

    if (!elem.prototype.toDataURL) return false;
    support = elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } catch (err) {
    return false;
  }

  return support;
};

export const webpxpng = (imageName) => {
  return imageName + (isWebpSupported() ? ".webp" : ".png");
};
export const webpxjpg = (imageName) => {
  return imageName + (isWebpSupported() ? ".webp" : ".jpg");
};
