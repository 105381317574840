//@ts-check
import React from "react";
import { Typography } from "@material-ui/core";
import Layout from "../../components/Layout";
import Contact from "../../components/Contact";
import Grayscale from "../../components/Grayscale";
import { webpxjpg } from "../../utils";

const prefix = process.env.PUBLIC_URL + "/images/";
const About1Img = prefix + webpxjpg("About1");
const About2Img = prefix + webpxjpg("About2");

const gutter = {
  marginBottom: "1rem",
};
const listItem = {
  marginBottom: 8,
};

const About = () => {
  return (
    <Layout
      helmet={{
        title: "Who we are",
        description: "About us",
        url: "about",
      }}
      secondChildren={<Contact />}
    >
      <Typography
        variant="h4"
        align="center"
        style={{ maxWidth: 800, margin: "auto", marginBottom: 40 }}
      >
        The ultimate mix <br />
        between creativity and production
      </Typography>
      <Typography style={gutter}>
        <b>Writecut</b> is a hybrid multimedia creative production studio that
        combines the best of two worlds: multimedia technology and creativity.
      </Typography>
      <Typography style={gutter}>
        <b>Our team</b> is composed of highly experienced creatives and
        production professionals who joined forces in order to develop unique
        premium-quality content. We’ve been producing creative content for over{" "}
        <b>10 years</b> now in the ever so changing field of advertising.
      </Typography>
      <Typography style={gutter}>
        <b>Our extensive agency background</b> gives a us a comprehensive
        understanding of clients’ needs and thus our studio has{" "}
        <b>
          the necessary resources to handle and manage big projects smoothly
        </b>{" "}
        without any intermediaries or hiccups.
      </Typography>

      <Grayscale src={About1Img} alt="About us" />
      <Typography style={gutter}>
        <b>Our dynamic portfolio speaks for itself:</b> complex projects for
        prodigious international brands and agencies; complemented by smaller
        spontaneous jobs or pro-bono work - if we truly believe in a cause.{" "}
      </Typography>

      <Typography style={gutter}>
        All of these assets reflect the <b>must-haves</b> that make us your
        go-to production studio
      </Typography>
      <ul>
        <li style={listItem}>
          <b>Flexibility</b> – we implement and adjust to short term change in
          the blink of an eye
        </li>
        <li style={listItem}>
          <b>Fast delivery</b> – tight deadline? No worries, with experience
          comes speed
        </li>
        <li style={listItem}>
          <b>Understanding a brand’s needs</b> – hundreds of projects later, we
          gathered valuable insights that come in handy every day
        </li>
        <li style={listItem}>
          <b>Sterling brand management</b> – when we work with a brand we don’t
          rest until everything is in place and ready for lift off
        </li>
        <li style={listItem}>
          <b>Efficient client service</b> – our resourceful team will make sure
          your expectations will always be exceeded
        </li>
        <li style={listItem}>
          <b>Vast network of approved professionals</b> – we collaborate with
          top notch directors, actors and logistic teams
        </li>
      </ul>
      <Typography style={gutter}>
        Our pledge, empowered by <b>passion</b>, is that{" "}
        <b>we’ll bring your brand’s story to life</b> through strategical and
        creative concepts - specifically tailored for your target - and
        top-grade production content using innovative multimedia tools.
      </Typography>

      <Grayscale src={About2Img} alt="About us" />
      <Typography style={gutter}>
        Employing emotionally engaging strategies combined with the latest
        cinematic effects, we shape an end result - that will not only take the
        brand to the next level in the eyes of your customers - but you will be
        proud to show off to your friends and work colleagues.
      </Typography>

      <Typography style={gutter}>
        Just come up with a brief and we’ll devise the best personalized
        solution.
      </Typography>

      <Typography style={gutter}>
        <b>Create. Set. Action!</b>
      </Typography>
    </Layout>
  );
};

export default About;
