import React, { Suspense } from "react";

const MaterialForm = React.lazy(() => import("react-material-ui-form"));

const MaterialFormLib = (props) => {
  return (
    <div>
      <Suspense fallback={<div>{props.children}</div>}>
        <MaterialForm {...props} />
      </Suspense>
    </div>
  );
};

export default MaterialFormLib;
