//@ts-check
import React from "react";
import { webpxpng } from "../../utils";
import Link from "../Link";

const prefix = process.env.PUBLIC_URL + "/images/";
const LogoImg = prefix + webpxpng("Logo");
const Logo = () => {
  return (
    <Link
      name="Writecut homepage link"
      to="/"
      color="inherit"
      style={{
        minWidth: 242,
        paddingTop: 10,
      }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
    >
      <img src={LogoImg} alt="Writecut logo" style={{ height: 40 }} />
    </Link>
  );
};

export default Logo;
