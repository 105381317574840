import { makeStyles, createStyles, IconButton } from "@material-ui/core";
import React from "react";
import { RiPlayCircleLine, RiCloseFill } from "react-icons/ri";
import ReactPlayer from "./ReactPlayer";

const useStyles = makeStyles((theme) =>
  createStyles({
    playing: {
      position: "fixed",
      top: "50%",
      marginTop: "-25%",
      left: -8,
      right: -8,
      zIndex: 999,
      bottom: 0,
      "& video:focus": {
        outline: "none",
      },
      "@media (orientation: landscape)": {
        bottom: "0px !important",
      },
      [theme.breakpoints.down("sm")]: {
        bottom: "25%",

        "@media (orientation: landscape)": {
          bottom: "25% !important",
        },
      },
    },
    preview: {
      width: "100%",
      transition: "filter .2s",
      "&:hover": {
        filter: "brightness(0.6)",
      },
    },
    playButton: {
      position: "absolute",
      fontSize: "120px",
      color: "#d0d6da",
      left: "50%",
      top: "50%",
      marginTop: "-60px",
      marginLeft: "-60px",
      pointerEvents: "none",
    },
    closeButton: {
      position: "fixed",
      color: "white",
      top: 12,
      right: 12,
      fontSize: "40px",
      zIndex: 9999,
      opacity: 0.6,
      "&:hover": {
        opacity: 1,
      },
    },
    overlay: {
      position: "fixed",
      backgroundColor: "black",
      opacity: 0.9,
      pointerEvents: "none",
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 99,
    },
    wrapper: {
      height: "100%",
    },
    reactPlayer: {
      position: "absolute",
      top: "0",
      left: "0",
    },
  })
);

const VideoPlayer = ({ url, preview }) => {
  const classes = useStyles({});
  const [playing, setPlaying] = React.useState(false);

  React.useEffect(() => {
    const cb = function (event) {
      if (playing && event.which === 27) setPlaying(false);
    };

    if (playing) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    document.addEventListener("keydown", cb);
    return () => {
      document.removeEventListener("keydown", cb);
    };
  }, [playing]);

  return (
    <>
      <>
        <img
          src={preview}
          alt="Video preview"
          className={classes.preview}
          onClick={() => {
            setPlaying(true);
            setTimeout(() => {
              const x = document.querySelector(
                "." + classes.reactPlayer + " > video"
              );
              x.focus();
            }, 500);
          }}
        />
        <RiPlayCircleLine className={classes.playButton} />
      </>
      {playing && (
        <>
          <div className={classes.overlay} />
          <IconButton
            name="Close player"
            aria-label="Close player"
            onClick={() => setPlaying(false)}
          >
            <RiCloseFill className={classes.closeButton} />
          </IconButton>
          <div className={classes.playing}>
            <div className={classes.wrapper}>
              <ReactPlayer
                className={classes.reactPlayer}
                playing
                url={url}
                width="100%"
                height="100%"
                controls
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VideoPlayer;
