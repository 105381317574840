//@ts-check
import React from "react";
import NavBar from "../NavBar";
import { Toolbar, AppBar } from "@material-ui/core";

const Header = () => {
  return (
    <>
      <AppBar>
        <NavBar />
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Header;
