//@ts-check
import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import Link from "../Link";
import VideoPlayer from "../VideoPlayer";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      // @ts-ignore
      height: ({ height }) => height,
      backgroundColor: "rgb(236, 233, 237)",
      border: "8px solid white",
      // @ts-ignore
      [theme.breakpoints.down("sm")]: {
        // @ts-ignore
        marginTop: theme.spacing(2),
        // @ts-ignore
        marginBottom: theme.spacing(2),
        // @ts-ignore
        marginLeft: -theme.spacing(2),
        // @ts-ignore
        marginRight: -theme.spacing(2),
      },
    },
    title: {
      // @ts-ignore
      marginBottom: -1 * theme.spacing(3),
      textTransform: "uppercase",
      fontSize: "2rem",
      // @ts-ignore
      [theme.breakpoints.down("sm")]: {
        marginBottom: 0,
      },
    },
    description: {
      // @ts-ignore
      opacity: ".65",
      textTransform: "uppercase",
      textAlign: "center",
      fontSize: ".8rem",
      // @ts-ignore
      fontWeight: "600",
      // @ts-ignore
      [theme.breakpoints.up("sm")]: {
        marginBottom: 8,
      },
      // @ts-ignore
      [theme.breakpoints.up("md")]: {
        marginTop: 16,
      },
      // @ts-ignore
      [theme.breakpoints.up("lg")]: {
        marginTop: 24,
      },
    },
    button: {
      position: "absolute",
      textAlign: "center",
      right: 40,
      bottom: 32,
      transform: "scale(1.2)",
      // @ts-ignore
      [theme.breakpoints.only("sm")]: {
        left: "50%",
        marginLeft: -77,
        right: "auto",
      },
    },
    image: {
      width: "100%",
      height: "100%",
      backgroundColor: "black",
      // @ts-ignore
      backgroundImage: ({ image }) => (image ? `url(${image})` : ""),
      backgroundSize: "contain",
      filter: "brightness(85%)",

      // @ts-ignore
      [theme.breakpoints.down("md")]: {
        backgroundSize: "cover",
      },
    },
  })
);

const Thumbnail = (props) => {
  const { title, description, video, to } = props;
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      {title && (
        <Typography
          component="p"
          variant="h6"
          align="center"
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography component="div" className={classes.description}>
          {description}
        </Typography>
      )}
      <div
        className={classes.root}
        onClick={() => {
          if (to) history.push(to);
        }}
      >
        <div className={classes.image} />
        {video && (
          <VideoPlayer url={video} preview={props.preview}></VideoPlayer>
        )}
        {to && (
          <div className={classes.button}>
            <Link name={props.button} variant="contained" to={to}>
              {props.button}
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

Thumbnail.defaultProps = {
  height: "auto",
  image: null,
};

export default Thumbnail;
