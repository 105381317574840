//@ts-check
import React from "react";
import { Typography } from "@material-ui/core";
import Layout from "../../components/Layout";
import Contact from "../../components/Contact";
import VideoList from "../../components/VideoList";
import Grayscale from "../../components/Grayscale";
import { webpxjpg } from "../../utils";

const prefix = process.env.PUBLIC_URL + "/images/";
const MultimediaImg = prefix + webpxjpg("Multimedia");
const CreativeImg = prefix + webpxjpg("Creative");

/**@type {any} */
const subheader = {
  fontSize: "1.3rem",
  fontWeight: "bold",
};

const Portfolio = () => {
  React.useEffect(() => {
    if (document.location.hash) {
      const h = document.location.hash.replace("#", "");
      document.getElementById(h).scrollIntoView(true);
    }
  }, []);

  return (
    <Layout
      helmet={{
        title: "What we do",
        description: "Portfolio",
        url: "portfolio",
      }}
      secondChildren={<Contact />}
    >
      <Typography style={{ marginBottom: 24, fontSize: "1.1rem" }}>
        Our services are especially thought-out to fulfill all of your brand’s
        multimedia needs and beyond. From copywriting and strategy to art
        direction, animation, audio/video production and interactive technology,
        we got you covered.
      </Typography>

      <>
        <div id="multimedia" style={{ height: 40 }} />
        <VideoList
          title={
            <>
              <Grayscale
                style={{ objectFit: "cover", height: "auto" }}
                width={1280}
                height={350}
                saturation={0.4}
                src={MultimediaImg}
                alt="Multimedia portfolio"
              />
              Multimedia Production
            </>
          }
          description={
            <>
              <Typography
                style={{ marginTop: 16, marginBottom: 16, ...subheader }}
              >
                Video Production
              </Typography>

              <Typography gutterBottom>
                Our main goal is to put your brand’s story into a remarkable
                video that captivates and really moves your audience. In order
                to do so, we make use of the latest technologies, including
                state of the art cameras, high-tech equipment and
                ground-breaking custom filming and editing techniques.
              </Typography>
              <>
                <Typography
                  gutterBottom
                  style={{ fontWeight: "bold", marginTop: 8 }}
                >
                  Budgets
                </Typography>

                <Typography gutterBottom>
                  Our wide range of production services can fit any budget and
                  meet any need your brand might have.
                </Typography>

                <Typography style={{ fontWeight: "bold" }} gutterBottom>
                  Big or small, we’ve got a team for anything
                </Typography>

                <Typography gutterBottom>
                  We’ll take care of everything to the smallest of details, so
                  you can sit back and enjoy the ride.
                </Typography>

                <Typography style={{ fontWeight: "bold" }} gutterBottom>
                  Network of services
                </Typography>

                <Typography gutterBottom>
                  From production management to scripting, casting, location
                  scouting, directing, illustrating or filming - we take care of
                  it all, so your project will get the best video shots you
                  could ever imagine.
                </Typography>
              </>

              <div style={{ height: 24 }} />

              <Typography style={{ marginBottom: 16, ...subheader }}>
                Post Production
              </Typography>

              <Typography gutterBottom>
                This is where talent meets experience and creativity that shapes
                your visual story in a spectacularly distinctive way.
              </Typography>
              <Typography>We deliver full editing sessions:</Typography>

              <Typography component="div" style={{ marginTop: -8 }}>
                <ul>
                  <li>
                    Video Editing, Color Grading, Digital Color Restoration
                  </li>
                  <li>Animation, Video Explainer, Kinetic Typography</li>
                  <li>Voice Over, Sound Design, Mastering and much more</li>
                </ul>
              </Typography>

              <div style={{ height: 16 }} />

              <Typography gutterBottom>
                Add to all this a spark of originality, then give it a visionary
                twist and you’ll get an astonishing final product that will
                offer your viewers a visual experience to remember.
              </Typography>
              <Typography gutterBottom>
                Clients have always valued our team’s display of exceptional
                editing skills and that motivates us to constantly improve our
                methods while innovating in every area possible so that we’re
                always one step ahead of the market.
              </Typography>
              <Typography style={{ fontWeight: "bold" }} gutterBottom>
                Your video’s second chance
              </Typography>
              <Typography gutterBottom>
                Has your camera man ruined your project? You’re not happy with a
                previous video or is it just lacking color or texture? Don t
                worry. We can fix it.
              </Typography>

              <Typography
                style={{ marginBottom: 16, marginTop: 32, ...subheader }}
              >
                Interactive technology
              </Typography>

              <Typography gutterBottom>
                If you’re looking to stand out and create awareness in a unique
                way, then projection mapping, live visuals and all sorts of
                interactive installations can be developed and customized so you
                can literally light up your brand’s presence anytime and
                anywhere.
              </Typography>
            </>
          }
        />
      </>
      <>
        <div id="creative" style={{ height: 60 }} />
        <VideoList
          title={
            <>
              <Grayscale
                style={{ objectFit: "cover", height: "auto" }}
                width={1280}
                height={350}
                saturation={0.8}
                src={CreativeImg}
                alt="Creative portfolio"
              />
              Creative
            </>
          }
          description={
            <>
              <Typography style={{ marginBottom: 16, ...subheader }}>
                Copywriting & Art direction
              </Typography>

              <Typography gutterBottom style={{ fontWeight: 600 }}>
                Welcome to the creative side. We got... well, everything.
              </Typography>
              <br />
              <>
                One of the founders having an extended advertising background
                with years of client-agency experience, the art of putting great
                ideas into well-crafted words is in our nature. So, rest assured
                that when it comes to concept development and top-selling
                content we are true artisans.
              </>
              <Typography style={{ marginTop: 8 }}>
                Our copy services include but are not limited to:
              </Typography>

              <Typography component="div">
                <ul>
                  <li>
                    360 TV & Online Campaigns (commercials & adjacent materials)
                  </li>
                  <li>Branding (naming, slogan, brand pillars etc)</li>
                  <li>Website content & brochure content</li>
                  <li>Explainer video script / animation script</li>
                  <li>Radio spots</li>
                  <li>Event concepts & promo</li>
                  <li>Product promo campaigns</li>
                  <li>Brand activation concepts</li>
                  <li>Consumer/in-store promo</li>
                  <li>Social media ads + content</li>
                  <li>YouTube pre-roll commercials</li>
                  <li>Interviews & press releases</li>
                </ul>
              </Typography>

              <div style={{ height: 16 }} />

              <>English or Romanian? Doesn’t matter. We rock both.</>
              <>
                And since great <b>art direction</b> goes hand in hand with that
                kick-ass copy you need, we work with some of the best graphic
                designers the market has to offer. We provide{" "}
                <b>graphic services</b> ranging from original illustrations, key
                visuals and brand books to logos, product design and promo
                materials.
              </>
              <Typography
                style={{ marginTop: 32, marginBottom: 16, ...subheader }}
              >
                Strategy
              </Typography>

              <Typography gutterBottom>
                Building a brand strategy at the right time is one of the
                essentials when it comes to long-term success. You know your
                brand is more than your logo. Make it an entire experience for
                your customers to enjoy.
              </Typography>

              <Typography gutterBottom>
                Access to <b>market studies & research, brand intelligence</b>{" "}
                and sound
                <b> know-how</b> are the building blocks we use to devise a
                rock-solid brand strategy. A strategy that defines what you
                stand for, the promise you make, and the personality you convey.
                Most importantly it differentiates you from the other players on
                the market.{" "}
              </Typography>

              <Typography gutterBottom>
                Strategy helps you establish (and communicate in a relevant way)
                your <b>unique</b> selling proposition and then pin-points the
                best ways to make your brand message memorable. It lays the
                foundation of a long-term emotional connection between you and
                your clients and that’s why every giant successful brand out
                there started off with a great idea/product, a handful of
                ambitious people and a strong brand strategy. We are here to
                help you become the next one.
              </Typography>
            </>
          }
        />
      </>
    </Layout>
  );
};

export default Portfolio;
