//@ts-check
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      maxWidth: "100%",
      marginBottom: 16,
      // @ts-ignore
      filter: ({ saturation }) => `saturate(${saturation})`,
      transition: "filter .2s",
      "&:hover": {
        filter: "none",
      },
    },
  })
);

const Grayscale = (props) => {
  const classes = useStyles({
    saturation: props.saturation,
  });
  return <img alt="grayscale" {...props} className={classes.image} />;
};

Grayscale.defaultProps = {
  saturation: 0,
};

export default Grayscale;
