//@ts-check
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { makeStyles, createStyles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import { Helmet } from "react-helmet";
import Hero from "./Hero";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: "calc(100vh - 128px)",
      // @ts-ignore
      paddingTop: theme.spacing(8),
    },
    root2: {
      backgroundColor: "#fafafa",
      // @ts-ignore
      marginTop: ({ offsetPx }) => -offsetPx,
      // @ts-ignore
      paddingTop: ({ offsetPx }) => offsetPx,
    },
    bottomSpace: {
      // @ts-ignore
      height: theme.spacing(8),
    },
  })
);

const site = "https://writecut.com/";
const app = " - Writecut - Remarkable content creation";

const Layout = ({ helmet, children, secondChildren, offsetPx, hero }) => {
  const classes = useStyles({ offsetPx });
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {helmet && (
        <Helmet>
          {helmet.title && (
            <title>
              {helmet.title}
              {helmet.noPrefix ? "" : app}
            </title>
          )}
          {helmet.description && (
            <meta name="description" content={helmet.description} />
          )}
          {helmet.url && <link rel="canonical" href={`${site}${helmet.url}`} />}
        </Helmet>
      )}
      <Header />
      {hero && <Hero {...hero} />}
      <Container className={classes.root} maxWidth="lg">
        {children}
        <div className={classes.bottomSpace} />
      </Container>
      {secondChildren && (
        <div className={classes.root2}>
          <Container maxWidth="lg">
            {secondChildren}
            <div className={classes.bottomSpace} />
          </Container>
        </div>
      )}
      <Footer />
    </>
  );
};

Layout.defaultProps = {
  hero: null,
  offsetPx: 0,
  secondChildren: null,
};

export default Layout;
