//@ts-check
import React from "react";
import { Typography } from "@material-ui/core";

const VideoList = ({ title, description }) => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            backgroundColor: "black",
            height: "100%",
            left: 0,
            right: 0,
            marginLeft: -400,
            marginRight: -400,
          }}
        />
        <Typography
          variant="h4"
          gutterBottom
          style={{
            backgroundColor: "black",
            color: "white",
            paddingBottom: 24,
            position: "relative",
            fontSize: "1.9rem",
          }}
        >
          {title}
        </Typography>
      </div>
      <Typography component="div">{description}</Typography>
    </>
  );
};

export default VideoList;
